import * as Yup from "yup";
import { testValidPostcode } from "../lib/testValidPostcode";
var SuggestAVenueVenueSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    name: Yup.string().required('Required'),
    address: Yup.object().shape({
        addressLine1: Yup.string().required("Address Line 1 is required"),
        addressLine2: Yup.string(),
        addressLine3: Yup.string(),
        county: Yup.string(),
        postcode: Yup.string().required('Postcode is required').test('post-code', "Please enter a valid UK or Irish Postcode", testValidPostcode),
        latLng: Yup.string(),
        placeID: Yup.string()
    }),
    image: Yup.mixed().required('Please upload an image')
});
export var SuggestAVenueSchema = Yup.object().shape({
    user: Yup.object().shape({
        emailAddress: Yup.string().email('Please enter a valid email').required('Required'),
        name: Yup.string().required("Required"),
        hideSurname: Yup.boolean(),
    }),
    venue: SuggestAVenueVenueSchema,
    review: Yup.object().shape({
        dateOfVisit: Yup.string().required('Required'),
        rating: Yup.number().moreThan(0, 'Please select a rating').required('Required'),
        title: Yup.string().required('Required'),
        content: Yup.string().required('Required'),
        menuTopTips: Yup.string(),
        venueTopTips: Yup.string(),
        recommendation: Yup.string(),
        allergy: Yup.array(Yup.string()).min(1, 'You must select one allergy.'),
    }),
    acceptTerms: Yup.boolean().required().isTrue('You must accept the terms and conditions')
});
export var SuggestAVenueTouched = {
    user: {
        emailAddress: true,
        name: true,
    },
    review: {
        content: true,
        dateOfVisit: true,
        rating: true,
        title: true,
        allergy: true
    },
    acceptTerms: true
};
