var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Lib
import React, { lazy, Suspense } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Modal from "react-modal";
import API from "../lib/API";
import { format } from "date-fns";
// Components
import UserForm from "../components/UserForm";
import { checkboxesFallback } from "../components/fallbacks/checkboxesFallback";
import { tooltipFallback } from "../components/fallbacks/tooltipFallback";
var DateSelection = lazy(function () { return import('../components/DateSelection'); });
var VenueRating = lazy(function () { return import('../components/VenueRating'); });
var AllergenSelection = lazy(function () { return import('../components/AllergenSelection'); });
var SuggestAVenueSummary = lazy(function () { return import('../components/Summaries/SuggestAVenueSummary'); });
var Checkbox = lazy(function () { return import('../components/Checkbox'); });
var NearbyVenues = lazy(function () { return import('../components/NearbyVenues'); });
var InputTooltip = lazy(function () { return import('../components/InputTooltip'); });
// Helpers
import { getGroupClass } from "../lib/GroupClass";
import { renderErrorMessage } from "../lib/renderErrorMessage";
import { SuggestAVenueSchema } from "../schemas/SuggestAVenue";
var SuggestAVenue = /** @class */ (function (_super) {
    __extends(SuggestAVenue, _super);
    function SuggestAVenue() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            addressSelected: false,
            showForm: true,
            showSummary: false,
            showComplete: false,
            proceedCheck: false,
            manualAddressInput: false,
            venueAlreadyExists: false,
            venueExistsURL: '',
            venue: null
        };
        _this.defaultValues = {
            user: {
                emailAddress: '',
                name: '',
                hideSurname: false,
                marketingOptIn: true
            },
            venue: {
                id: '',
                name: '',
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    county: '',
                    postcode: '',
                    latLng: '',
                    placeID: ''
                },
                website: '',
                telephoneNumber: '',
                image: '',
                description: ''
            },
            review: {
                dateOfVisit: format(new Date(), 'yyyy-MM-dd'),
                rating: 0,
                title: '',
                content: '',
                recommendation: '',
                menuTopTips: '',
                venueTopTips: '',
                allergy: [],
            },
            acceptTerms: true
        };
        _this.saveVenue = function (values, actions) {
            actions.setSubmitting(true);
            API.suggestVenue(values)
                .then(function () {
                actions.resetForm({ values: _this.defaultValues });
                _this.setState({ showSummary: false, showComplete: true, showForm: false }, _this.triggerNavChange);
                window.scrollTo(0, 0);
            })
                .catch(function (err) {
                console.error('[API.suggestVenue] ' + err.message);
            })
                .finally(function () {
                actions.setSubmitting(false);
                if (typeof gtag !== 'undefined') {
                    gtag('event', 'page_view', {
                        page_title: document.title,
                        page_location: window.location.href + "thank-you-review",
                        page_path: window.location.pathname + "thank-you-review"
                    });
                    gtag('event', "leave_review_form_complete", {
                        'event_category': "leave_review_form",
                        'event_label': "Review Venue"
                    });
                }
            });
        };
        _this.render = function () {
            var taxonomySkeleton = checkboxesFallback();
            var checkboxSkeleton = React.createElement("span", { className: "checkbox-dummy" }, "Loading");
            var toolTipSkeleton = tooltipFallback();
            return (React.createElement(Formik, { initialValues: _this.defaultValues, onReset: _this.resetElements, validationSchema: SuggestAVenueSchema, validateOnMount: true, onSubmit: _this.saveVenue }, function (props) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
                var errors = props.errors;
                var touched = props.touched;
                var venueInput = _this.renderVenueInput(errors.venue, touched.venue, true);
                var formClass = _this.state.addressSelected ? "form venue-selected" : "form";
                return (React.createElement(Form, { className: formClass, onKeyDown: _this.onKeyDown, autoComplete: "off" },
                    React.createElement("div", { id: "suggest-a-venue__form", style: { display: (_this.state.showForm) ? 'block' : 'none' } },
                        React.createElement("h1", { className: "page-title" }, "Review a venue"),
                        venueInput,
                        React.createElement("div", { id: "suggest-a-venue__other", className: "form__group", style: {
                                display: (_this.state.addressSelected || _this.state.manualAddressInput) ? 'block' : 'none'
                            } },
                            React.createElement("fieldset", { className: "form__fieldset", ref: _this.firstFieldset },
                                React.createElement("legend", null, "Your Review"),
                                React.createElement("div", { className: getGroupClass((_a = errors.review) === null || _a === void 0 ? void 0 : _a.dateOfVisit, (_b = touched.review) === null || _b === void 0 ? void 0 : _b.dateOfVisit) },
                                    React.createElement("label", { htmlFor: "reviewDateOfVisit", className: "form__label" }, "Date of visit *"),
                                    React.createElement(Field, { id: "reviewDateOfVisit", name: "review.dateOfVisit", className: "form__control" }, function (props) { return (React.createElement(Suspense, { fallback: React.createElement("div", { className: "form__control" }) },
                                        React.createElement(DateSelection, __assign({}, props, { ref: _this.dateSelection })))); }),
                                    React.createElement(ErrorMessage, { name: "review.dateOfVisit", render: renderErrorMessage })),
                                React.createElement("div", { className: getGroupClass((_c = errors.review) === null || _c === void 0 ? void 0 : _c.rating, (_d = touched.review) === null || _d === void 0 ? void 0 : _d.rating) },
                                    React.createElement(Field, { id: "reviewRating", name: "review.rating" }, function (props) { return (React.createElement(Suspense, { fallback: React.createElement("div", { className: "form__control" }) },
                                        React.createElement(VenueRating, __assign({}, props, { label: "How well did this venue do?" })))); }),
                                    React.createElement(ErrorMessage, { name: "review.rating", render: renderErrorMessage }))),
                            React.createElement("fieldset", { className: "form__fieldset" },
                                React.createElement("div", { className: getGroupClass((_e = errors.review) === null || _e === void 0 ? void 0 : _e.allergy, (_f = touched.review) === null || _f === void 0 ? void 0 : _f.allergy) },
                                    React.createElement(Field, { name: "review.allergy" }, function (props) { return (React.createElement(Suspense, { fallback: taxonomySkeleton },
                                        React.createElement(AllergenSelection, __assign({ ref: _this.allergenSelection, introTitle: "Please select allergens *", introContent: "What allergens was the venue able to avoid?" }, props)))); }),
                                    React.createElement(ErrorMessage, { name: "review.allergy", render: renderErrorMessage }))),
                            React.createElement("fieldset", { className: "form__fieldset" },
                                React.createElement("div", { className: getGroupClass((_g = errors.review) === null || _g === void 0 ? void 0 : _g.title, (_h = touched.review) === null || _h === void 0 ? void 0 : _h.title) },
                                    React.createElement("label", { htmlFor: "reviewTitle", className: "form__label" }, "Title of review *"),
                                    React.createElement(Field, { id: "reviewTitle", name: "review.title", className: "form__control", placeholder: "Enter a title for your review (max 90 chars.)" }),
                                    React.createElement(ErrorMessage, { name: "review.title", render: renderErrorMessage })),
                                React.createElement("div", { className: getGroupClass((_j = errors.review) === null || _j === void 0 ? void 0 : _j.content, (_k = touched.review) === null || _k === void 0 ? void 0 : _k.content) },
                                    React.createElement("label", { htmlFor: "reviewContent", className: "form__label" }, "Your review *"),
                                    React.createElement(Suspense, { fallback: toolTipSkeleton },
                                        React.createElement(InputTooltip, { title: "Your review", content: "Please tell us about your experience, how/if the venue was able to accommodate your dietary requirements, and any adjustments they were able to make." })),
                                    React.createElement(Field, { id: "reviewContent", name: "review.content", className: "form__control", as: "textarea", rows: "6", placeholder: "Write your review here (max 600 chars.)" }),
                                    React.createElement(ErrorMessage, { name: "review.content", render: renderErrorMessage })),
                                React.createElement("div", { className: getGroupClass((_l = errors.review) === null || _l === void 0 ? void 0 : _l.menuTopTips, (_m = touched.review) === null || _m === void 0 ? void 0 : _m.menuTopTips) },
                                    React.createElement("label", { htmlFor: "reviewMenuTopTips", className: "form__label" }, "Menu top tips"),
                                    React.createElement(Suspense, { fallback: toolTipSkeleton },
                                        React.createElement(InputTooltip, { title: "Menu top tips", content: "Anything worth mentioning about the menu, e.g. allergens listed or not" })),
                                    React.createElement(Field, { id: "reviewMenuTopTips", name: "review.menuTopTips", className: "form__control", as: "textarea", rows: "3", placeholder: "Write down some menu tips (max 120 chars.)" }),
                                    React.createElement(ErrorMessage, { name: "review.menuTopTips", render: renderErrorMessage })),
                                React.createElement("div", { className: getGroupClass((_o = errors.review) === null || _o === void 0 ? void 0 : _o.venueTopTips, (_p = touched.review) === null || _p === void 0 ? void 0 : _p.venueTopTips) },
                                    React.createElement("label", { htmlFor: "reviewVenueTopTips", className: "form__label" }, "Venue top tips"),
                                    React.createElement(Suspense, { fallback: toolTipSkeleton },
                                        React.createElement(InputTooltip, { title: "Venue top tips", content: "Anything worth mentioning about the venue, e.g. call and book in advance" })),
                                    React.createElement(Field, { id: "reviewVenueTopTips", name: "review.venueTopTips", className: "form__control", as: "textarea", rows: "3", placeholder: "Write down some venue tips (max 120 chars.)" }),
                                    React.createElement(ErrorMessage, { name: "review.venueTopTips", render: renderErrorMessage })),
                                React.createElement("div", { className: getGroupClass((_q = errors.review) === null || _q === void 0 ? void 0 : _q.recommendation, (_r = touched.review) === null || _r === void 0 ? void 0 : _r.recommendation) },
                                    React.createElement("label", { htmlFor: "reviewRecommendation", className: "form__label" }, "What dish do you recommend?"),
                                    React.createElement(Field, { id: "reviewRecommendation", name: "review.recommendation", className: "form__control", placeholder: "Name the dish you most liked (120 chars.)" }),
                                    React.createElement(ErrorMessage, { name: "review.recommendation", render: renderErrorMessage }))),
                            React.createElement("fieldset", { className: "form__fieldset" },
                                React.createElement("legend", null, "Your Details"),
                                React.createElement("div", { className: getGroupClass((_s = errors.user) === null || _s === void 0 ? void 0 : _s.emailAddress, (_t = touched.user) === null || _t === void 0 ? void 0 : _t.emailAddress) },
                                    React.createElement("label", { htmlFor: "email-address", className: "form__label" }, "Email Address *"),
                                    React.createElement(Suspense, { fallback: toolTipSkeleton },
                                        React.createElement(InputTooltip, { title: "Email address", content: "Please note, your email address will not be shown against your review." })),
                                    React.createElement(Field, { id: "email-address", name: "user.emailAddress", className: "form__control", placeholder: "Enter your email address" }),
                                    React.createElement(ErrorMessage, { name: "user.emailAddress", render: renderErrorMessage })),
                                React.createElement("div", { className: getGroupClass((_u = errors.user) === null || _u === void 0 ? void 0 : _u.name, (_v = touched.user) === null || _v === void 0 ? void 0 : _v.name) },
                                    React.createElement("label", { htmlFor: "user-name", className: "form__label" }, "Display Name *"),
                                    React.createElement(Suspense, { fallback: toolTipSkeleton },
                                        React.createElement(InputTooltip, { title: "Display Name", content: "How you would like your name to be displayed. This can be your full name or nickname." })),
                                    React.createElement(Field, { id: "user-name", name: "user.name", className: "form__control", autocorrect: "off", placeholder: "Enter a display name for the review" }),
                                    React.createElement("div", { className: "form__addon" },
                                        React.createElement(Field, { id: "show-name", name: "user.hideSurname" }, function (props) { return (React.createElement(Suspense, { fallback: checkboxSkeleton },
                                            React.createElement(Checkbox, __assign({}, props, { label: "Don’t show my full name on the review", isNameCheckbox: true })))); })),
                                    React.createElement(ErrorMessage, { name: "user.name", render: renderErrorMessage }))),
                            React.createElement("fieldset", { className: "form__fieldset" },
                                React.createElement("div", { className: "form__group" },
                                    React.createElement(Field, { id: "show-name", name: "acceptTerms" }, function (props) { return (React.createElement(Suspense, { fallback: checkboxSkeleton },
                                        React.createElement(Checkbox, __assign({}, props, { label: "I agree to the <a href='/terms' target='_blank'>Terms and Conditions</a> and <a href='/acceptable-use-policy' target='_blank'>Acceptable Use Policy</a>", isNameCheckbox: false })))); }),
                                    React.createElement(ErrorMessage, { name: "acceptTerms", render: renderErrorMessage })),
                                React.createElement("div", { className: "form__group" },
                                    React.createElement(Field, { id: "opt-in", name: "user.marketingOptIn" }, function (props) { return (React.createElement(Suspense, { fallback: checkboxSkeleton },
                                        React.createElement(Checkbox, __assign({}, props, { label: "Opt in to receive our newsletter", isNameCheckbox: false })))); })))),
                        React.createElement("div", { className: "form__actions", style: {
                                display: (_this.state.addressSelected || _this.state.manualAddressInput) ? 'block' : 'none'
                            } },
                            React.createElement("button", { className: "".concat(props.isValid ? "btn" : "btn btn--invalid", " ").concat(props.isSubmitting ? 'btn--loading' : ''), type: "submit", disabled: props.isSubmitting }, props.isSubmitting
                                ?
                                    React.createElement("div", { className: "loading loading--small" },
                                        React.createElement("svg", { className: "loading-animation loading__icon" },
                                            React.createElement("use", { xlinkHref: "#icon--loading-white" })))
                                : React.createElement("span", null, "Submit review")))),
                    React.createElement("div", { id: "suggest-a-venue__thanks", style: { display: (_this.state.showComplete) ? 'block' : 'none' } },
                        React.createElement("section", { className: "thankyou" },
                            React.createElement("img", { src: "/wp-content/themes/class/dist/images/thankyou.jpg", alt: "Thank you image", className: "thankyou__image" }),
                            React.createElement("h3", { className: "h1" }, "Thank you!"),
                            React.createElement("p", null, "Your review will be approved by a member of the AC team and we will notify you when it has been published.")),
                        _this.state.venue !== null && (React.createElement(Suspense, { fallback: React.createElement("div", null) },
                            React.createElement(NearbyVenues, { latLng: _this.state.venue.address.latLng, areaLabel: _this.state.venue.address.county })))),
                    React.createElement(Modal, { id: "already-exists", isOpen: _this.state.venueAlreadyExists, contentLabel: "Venue Already Exists", style: {
                            content: {
                                maxWidth: "40rem",
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                            },
                        } },
                        React.createElement("div", { className: "modal__content" },
                            React.createElement("h3", { className: "h1 ReactModal__title" }, "Selected venue already exists"),
                            React.createElement("div", { className: "form__group" },
                                React.createElement("p", null, "Click below to leave a review for the selected venue:"),
                                React.createElement("a", { href: _this.state.venueExistsURL, className: "btn btn--secondary" }, "Leave a review")),
                            React.createElement("div", { className: "form__group" },
                                React.createElement("p", null, "Alternatively click below if you want to select a different venue:"),
                                React.createElement("button", { type: "button", className: "btn btn--outline", onClick: function () {
                                        _this.setState({
                                            venueAlreadyExists: false,
                                            venueExistsURL: '',
                                            venue: null
                                        });
                                    } }, "Go back"))))));
            }));
        };
        return _this;
    }
    SuggestAVenue.prototype.componentDidMount = function () {
        if (typeof gtag !== 'undefined') {
            gtag('event', 'page_view', {
                page_title: document.title,
                page_location: window.location.href,
                page_path: window.location.pathname
            });
            gtag('event', "leave_review_form_start", {
                'event_category': "leave_review_form",
                'event_label': "Review Venue"
            });
        }
        window.addEventListener('scroll', this.affixNav, { passive: true });
    };
    SuggestAVenue.prototype.componentWillUnmount = function () {
        window.removeEventListener('scroll', this.affixNav);
    };
    return SuggestAVenue;
}(UserForm));
export default SuggestAVenue;
